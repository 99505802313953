.dash.visualizations {
    display: flex;
    flex-direction: column;
    width: 65%;
}
@media (min-width: 500px) {
    .dash.visualizations {
        width: 100%;
    }
}

.dash .nav-tabs .nav-link {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.dash .nav-tabs .nav-item.show .nav-link, .dash .nav-tabs .nav-link.active {
    color: #ffffff;
    background-color: #348EAC;
}

.note {
    margin: 20px;
    text-align: center;
}