.draggable {
  border: 2px #348eac dashed;
  border-radius: 3px;
}
.draggable label {
  cursor: grab;
}

.option-labels {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.value-option {
  margin-left: 15px;
}
.delete-option {
  background-color: transparent;
  border: 0;
  padding: 1px 0px 1px 6px;
}
.delete-option:hover {
  opacity: 0.7;
}
.delete-option img {
  height: 1rem;
  filter: invert(46%) sepia(95%) saturate(331%) hue-rotate(148deg)
    brightness(88%) contrast(86%);
  transition: opacity 0.2s;
}
